<template>
  <div class="main-result-page text-left">
    <div class="" v-if="isLoading">
      <p
        class="mt-3"
        style="
          color: rgb(49, 49, 49);
          text-align: center;
          padding: 10px;
          margin: 32px 10px 16px 0px;
          opacity: 1;
          transform: rotate(0deg);
     
          font-style: normal;
          text-decoration: unset;
          
          font-family: Raleway;
          font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 20px;
        "
      >
        Please hold on while we retrieve your results. This process might take a
        few moments.
      </p>
      <div
        v-for="index in 3"
        :key="index"
        class="my-2 integrationImgae bg-preload"
        style="height: 100px; width: 100%; border-radius: 14px"
      >
        <div class="into-preload"></div>
      </div>
    </div>

    <template v-else>
      <div class="row" v-if="results.length">
        <div 
          class="result col-12 col-md-12 mb-2"
          v-for="(result, index) in results"
          :key="index"
        >
          <div class="card p-0 d4w-card"  >
            <div class="card-body">
              <div class="row h-100">
                <div class="col-12 col-md-7">
                  <div class="">
                    <div class="" style="gap: 10px">
                      <div class="d-flex align-items-center gap">
                        <div
                          class="d-flex align-items-center gap justify-content-center gap"
                          style="
                            border: 1px solid #e3e3e3;
                            border-radius: 50%;
                            height: 40px;
                            width: 40px;
                            padding: 4px;
                          "
                        >
                          <lazy-image
                            :src="result.logo"
                            style="width: 100%; object-fit: contain"
                          ></lazy-image>
                        </div>
                        <div class="">
                          <h3 class="mt-2 space-bottom-sm">
                            {{ result.delivery_service }}
                          </h3>
                          <div class="d-flex flex-wrap">
                            <div class="d-flex align-items-center  d4w-desc">
                              <span
                                ><svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M7.75 3.5C7.75 3.08579 7.41421 2.75 7 2.75C6.58579 2.75 6.25 3.08579 6.25 3.5V3.90665C6.00413 3.96421 5.77128 4.04472 5.54754 4.15873C4.84193 4.51825 4.26825 5.09193 3.90873 5.79754C3.68239 6.24175 3.58803 6.7219 3.54336 7.26853C3.49999 7.79944 3.49999 8.45505 3.5 9.26789V10.7321C3.49999 11.5449 3.49999 12.2006 3.54336 12.7315C3.58803 13.2781 3.68239 13.7582 3.90873 14.2025C4.26825 14.9081 4.84193 15.4817 5.54754 15.8413C5.99175 16.0676 6.4719 16.162 7.01853 16.2066C7.54944 16.25 8.20505 16.25 9.01788 16.25L12.4013 16.25C12.7236 16.25 12.943 16.2501 13.1364 16.23C14.9018 16.0472 16.2972 14.6518 16.48 12.8864C16.5001 12.693 16.5 12.4736 16.5 12.1514L16.5 9.26788C16.5 8.45505 16.5 7.79944 16.4566 7.26853C16.412 6.7219 16.3176 6.24175 16.0913 5.79754C15.7317 5.09193 15.1581 4.51825 14.4525 4.15873C14.2287 4.04472 13.9959 3.96421 13.75 3.90665V3.5C13.75 3.08579 13.4142 2.75 13 2.75C12.5858 2.75 12.25 3.08579 12.25 3.5V3.75859C11.8765 3.74999 11.456 3.75 10.9821 3.75H9.0179C8.54396 3.75 8.12348 3.74999 7.75 3.75859V3.5ZM6.22852 5.49524C6.42604 5.3946 6.68681 5.32547 7.14068 5.28838C7.60331 5.25058 8.19755 5.25 9.05 5.25H10.95C11.8025 5.25 12.3967 5.25058 12.8593 5.28838C13.3132 5.32547 13.574 5.3946 13.7715 5.49524C14.1948 5.71095 14.5391 6.05516 14.7548 6.47852C14.8554 6.67604 14.9245 6.9368 14.9616 7.39068C14.9764 7.57123 14.9855 7.77183 14.991 8H5.00895C5.01455 7.77183 5.02363 7.57123 5.03838 7.39068C5.07547 6.9368 5.1446 6.67604 5.24524 6.47852C5.46095 6.05516 5.80516 5.71095 6.22852 5.49524ZM5 9.5V10.7C5 11.5525 5.00058 12.1467 5.03838 12.6093C5.07547 13.0632 5.1446 13.324 5.24524 13.5215C5.46095 13.9448 5.80516 14.289 6.22852 14.5048C6.42604 14.6054 6.68681 14.6745 7.14068 14.7116C7.60331 14.7494 8.19755 14.75 9.05 14.75H12.3557C12.7407 14.75 12.8759 14.749 12.9818 14.738C14.0411 14.6283 14.8783 13.7911 14.988 12.7318C14.999 12.6259 15 12.4907 15 12.1057V9.5H5Z"
                                    fill="#4A4A4A"
                                  />
                                </svg> </span
                              ><span class="">EST delivery: </span
                              ><span class="bold" v-if="result.time.time <= 1"
                                >within{{ result.time.time }} day.</span
                              ><span class="bold" v-else
                                >upto {{ result.time.time }} days</span
                              >
                            </div>
                            <div class="d4w-price d4w-desc d-flex">
                                Price: <span class="bold">${{ result.price }}</span>
                            </div>
                            <div class="d4w-price d4w-desc d-flex" v-if="cheapPriceIndex==index||minimumTimeIndex==index">
                                 <span class="bold"><span
                      v-if="cheapPriceIndex == index"
                      
                      >Cheapest</span
                    >
                    <span v-if="minimumTimeIndex == index&&cheapPriceIndex==index" class="px-1">-</span>
                    <span
                      v-if="minimumTimeIndex == index"
                      
                      >Fastest</span
                    >
                  </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-5">
                 
                  <div class="d-flex align-items-center h-100 justify-content-md-end mt-3 mt-md-0 justify-content-center">
                   <div class="">

                     <button
                  
                       class="d4w-button"
                       :disabled="index == isLoadingGetingLink"
                       tabindex="0"
                       type="button"
                       @click="selectItem(result, index)"
                     >
                       <b-spinner
                         small
                         v-if="index == isLoadingGetingLink"
                       ></b-spinner
                       ><span v-else>{{ properties.buttonText||'Select' }}</span>
                     </button>
                   </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-5" v-else>
        <div class="col-12 text-center">
          <p
            style="
              color: rgb(49, 49, 49);
              text-align: center;
              padding: 10px;
              margin: 32px 10px 16px 0px;
              opacity: 1;
              transform: rotate(0deg);
              line-height: 1.2;
              font-weight: bold;
              font-style: normal;
              text-decoration: unset;
              font-size: 22px;
              font-family: Raleway;
            "
          >
            We were unable to find a shipment carrier for your request .
          </p>
          <p
            style="
              color: rgb(49, 49, 49);
              text-align: center;
              padding: 10px;
              margin: 32px 10px 16px 0px;
              opacity: 1;
              transform: rotate(0deg);
              line-height: 1.2;
              font-weight: bold;
              font-style: normal;
              text-decoration: unset;
              font-size: 16px;
              font-family: Raleway;
            "
          >
            Please Try again
          </p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    pro: Object,
    apiToken: String,
    id: String,
  },

  computed: {
    getD4WBearerToken(){
     return process.env.VUE_APP_D4W_BEARER_TOKEN||'qz_cZnhyL3zYBDHEZMz8nK0zyhm8kyk8LHuYxOb9JY2uGZY4ohlNFBjzcq0NY5qAenBOCodbCxbzjEHdvgaLbqRHPUjmEGlnsqOFLcU'
    },
    getFrieghtQuotClient() {
      let env = this.getEnvConst?.FRIEGHTQUOT_CLIENT ?? [];
      if (!Array.isArray(env)) {
        // eslint-disable-next-line no-useless-escape
        env = env.replace(/([{,])(\s*)([A-Za-z0-9_\-]+?)\s*:/g, '$1"$3":');
        return env ? JSON.parse(env) : [];
      }
      return env;
    },
    ...mapGetters(["getEnvConst", "getSelectedOptions", "getSavedLeadID"]),
  },
  data() {
    return {
      results: [],
      isLoading: false,
      isLoadingGetingLink: null,
      cheapPriceIndex: -1,
      minimumTimeIndex: -1,
      customer_email: "",
      customer_id: "",
      d4w_quote_id: null,
      properties: {},
    };
  },
  methods: {

    findCheapPriceIndex() {
      let results = this.results;
      if (results.length === 0) {
        return -1; // Return -1 if the array is empty
      }

      return results.reduce((minIndex, currentItem, currentIndex) => {
        const currentCheapPrice = results[minIndex].price;
        const currentPrice = currentItem.price;
        return currentPrice < currentCheapPrice ? currentIndex : minIndex;
      }, 0);
    },
    findMinimumTimeIndex() {
      let results = this.results;
      if (results.length === 0) {
        return -1; // Return -1 if the array is empty
      }
      return results.reduce((minIndex, currentItem, currentIndex) => {
        const currentMinTime = results[minIndex].time.time;
        const currentTime = currentItem.time.time;
        return currentTime < currentMinTime ? currentIndex : minIndex;
      }, 0);
    },
    async selectItem(item, index) {
  this.isLoadingGetingLink = index;

  try {
    const token = this.getD4WBearerToken; // Access the computed property
    const response = await fetch("https://d4wapp.quizell.com/api/quiz/create-shopify-product", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`, // Use the computed Bearer token
      },
      body: JSON.stringify({

        ...item,
      }),
    });

    const data = await response.json();

    if (data.status) {
      window.open(data.data.checkout_url, "_blank");
    }
  } catch (error) {
    console.log("error", error);
  } finally {
    this.isLoadingGetingLink = null;
  }
},
    packageRate(rate_details) {
      let total = 0;
      // Loop through each item in the rate_details array
      for (let i = 0; i < rate_details.length; i++) {
        const detail = rate_details[i];
        // Checking if detail.amount exists and has a valid amount
        if (detail.amount) {
          // Adding the amount to the total
          total += detail.amount.amount;
        }
      }
      return parseFloat(total.toFixed(2));
    },
    async getResultsById() {
  try {
    this.isLoading = true;

    const payload = {
      d4w_qoute_id: this.d4w_quote_id,
    };

    const token = this.getD4WBearerToken; // Access the computed Bearer token

    const response = await fetch("https://d4wapp.quizell.com/api/d4w/quotes/rates", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`, // Use the computed token
      },
      body: JSON.stringify(payload),
    });

    const data = await response.json();

    if (data) {
      this.results = data.data.rates;
      this.properties = data.data.result_page.pageDesign.blocksArray.find(
        (block) => block.type == "product"
      );
      this.minimumTimeIndex = this.findMinimumTimeIndex();
      this.cheapPriceIndex = this.findCheapPriceIndex();
    }
  } catch (error) {
    console.error(error);
  } finally {
    this.isLoading = false;
  }
},
    async getResults() {
      try {
        this.isLoading = true;

        const payload = {
          shipment_from: {},
          shipment_to: {},
          from_details: {},
          to_details: {},
          leadId: this.getSavedLeadID?.id,
          packages: null,
          customer_email: this.customer_email,
          customer_id: this.customer_id,
          pickup_date: null,
          // delivery_date:null,
        };
        const questionIds = this.getFrieghtQuotClient.map(
          (question) => question.questionId
        );

        const selectedOptionsMap = new Map(
          this.getSelectedOptions.questionAnswer.map((option) => [
            option.questionId,
            option,
          ])
        );

        // From Detail
        if (selectedOptionsMap.has(questionIds[0])) {
          const question1 = selectedOptionsMap.get(questionIds[0]);
          payload.shipment_from = JSON.parse(question1.selectedOption[0].value);
          payload.pickup_date = question1.selectedOption[1].value;
          payload.from_details = JSON.parse(question1.selectedOption[2].value);
          // Adding Seconds
          payload.from_details.pickup_window.closing_at =
            payload.from_details.pickup_window.closing_at + ":00";
          payload.from_details.pickup_window.start_at =
            payload.from_details.pickup_window.start_at + ":00";
          payload.from_details.pickup_window.end_at =
            payload.from_details.pickup_window.end_at + ":00";
        }
        // packages
        if (selectedOptionsMap.has(questionIds[1])) {
          const question2 = selectedOptionsMap.get(questionIds[1]);
          payload.packages = question2.selectedOption[0].value;
        }
        // for options/services
        if (selectedOptionsMap.has(questionIds[2])) {
          const question3 = selectedOptionsMap.get(questionIds[2]);
          if (question3.selectedOption.length) {
            payload.options = JSON.parse(question3.selectedOption[0].value);
          }
        }
        // For To Detail
        if (selectedOptionsMap.has(questionIds[3])) {
          const question = selectedOptionsMap.get(questionIds[3]);
          payload.shipment_to = JSON.parse(question.selectedOption[0].value);
          // payload.delivery_date = question.selectedOption[1].value;
          payload.to_details = JSON.parse(question.selectedOption[1].value);
          if (payload.to_details.pickup_window)
            delete payload.to_details.pickup_window;
        }
        const token = this.getD4WBearerToken;
        const response = await fetch("https://d4wapp.quizell.com/api/quiz/get-shipping-rates", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`, // Use the computed Bearer token
      },
      body: JSON.stringify(payload),
    });

    const data = await response.json();

    if (data) {
      this.results = data.data;
      this.minimumTimeIndex = this.findMinimumTimeIndex();
      this.cheapPriceIndex = this.findCheapPriceIndex();
    }
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  created() {
    (this.customer_email = this.$route.query.customer_email || null),
      (this.customer_id = this.$route.query.customer_id || null);
    this.d4w_quote_id = this.$route.query.d4w_quote_id || null;
  },
  mounted() {
    if (this.d4w_quote_id) {
      this.getResultsById();
    } else {
      this.properties = { ...this.pro };
      this.getResults();
    }
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Yantramanav:wght@100;300;400;500;700;900&display=swap");
</style>
<style scope>
.main-result-page {
  font-family: "Yantramanav", sans-serif;
}

.css-1iivdyp:hover {
  background-color: rgba(0, 113, 199, 0.08);
  color: rgb(0, 73, 134);
}

.css-1iivdyp {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-weight: 500;
  text-transform: none;
  letter-spacing: 0.8px;
  line-height: 24px;
  font-family: Yantramanav, "Open Sans", sans-serif;
  min-width: 64px;
  border-radius: 4px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 1px solid rgba(0, 113, 199, 0.5);
  color: rgb(0, 113, 199);
  width: 100%;
  box-shadow: none;
  font-size: 16px;
  height: 40px;
  outline-offset: 2px;
  white-space: nowrap;
  padding: 8px 16px;
}

.extra-bold {
  font-weight: 700;
}

.primary-color {
  color: #00a3e0;
}

.space-bottom-sm {
  color: #303030;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.best-match-carrier {
  display: flex;
  margin-top: -8px;
}

.extra-bold {
  font-weight: 700;
}

.h1,
.h2,
.h3,
h1,
h2,
h3 {
  margin-bottom: 10px;
  margin-top: 20px;
}

.card {
  border: 1px solid #e5e6e7;
  border-radius: 8px;
  box-shadow: 0 0 0 0;
}

.no-space {
  margin: 0;
  padding: 0;
}

.no-space {
  margin: 0;
  padding: 0;
}

.bold {
  font-weight: 650;
  color: #303030!important;
  padding: 0px 2px;
}

.price-help,
.rating-wrapper {
  display: flex;
  justify-content: center;
}

.price-help {
  align-items: center;
  margin-block: 8px;
}

.emphasis {
  font-size: 24px;
}

a {
  color: #0078ae;
  text-decoration: none;
}
.d4w-card {
  border-radius: 12px;
  background: var(--Admin-Surface, #fff);

  /* Card/+1 Card */
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.07),
    0px -1px 0px 0px #d4d4d4 inset, -1px 0px 0px 0px #e3e3e3 inset,
    1px 0px 0px 0px #e3e3e3 inset, 0px 1px 0px 0px #e3e3e3 inset;
}
.d4w-desc {


  font-size: 13px;
  font-style: normal;
  color: #616161;
  line-height: 20px;
}
.d4w-price::before{
  display: flex;
content:'';
height: 100%;
width:1px;
margin: 0px 10px;
background: #D9D9D9;
}
.d4w-button{
  color: #303030;
    padding: 6px 12px;
    border: none;
    outline: none;
    font-size: 13px;
    font-style: normal;
    font-weight: 550;
    box-shadow: 0px -1px 0px 0px #B5B5B5 inset, -1px 0px 0px 0px #E3E3E3 inset, 1px 0px 0px 0px #E3E3E3 inset, 0px 1px 0px 0px #E3E3E3 inset;
    background: white;
    border-radius: 12px;
}
.d4w-button:active,.d4w-button:focus{
  box-shadow: 0px 1px 0px 0px #B5B5B5 inset, 1px 0px 0px 0px #E3E3E3 inset, -1px 0px 0px 0px #E3E3E3 inset, 0px -1px 0px 0px #E3E3E3 inset!important;

}
</style>
